<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
 
<script>
console.log("Footer");
import VRuntimeTemplate from "vue3-runtime-template";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
export default {
 name: "FooterTemplate",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  if (res.site && res.site.footer_copyright)
   res.site.footer_copyright = res.site.footer_copyright
    .replace("©", "&copy;")
    .replace("&copy;", "&copy;" + new Date().getFullYear());

  return {
   template: res.templates.footerTemplate.content,
   res: res,
  };
 },
 components: {
  VRuntimeTemplate,
 },
 methods: {
  swalFire(obj) {
   console.log("swalFire");
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
   });
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return ;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  submitFormJson(formID, actionUrl, succ_call, error_call) {
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     console.log(response);
     if (succ_call) succ_call();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (error_call) error_call();
    });
  },
 },
};
</script>
