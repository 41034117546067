<template>
 <v-runtime-template :template="template"></v-runtime-template>
</template>
<script>
import VRuntimeTemplate from "vue3-runtime-template";
import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
export default {
 name: "HeaderTemplate",
 data() {
  var res = JSON.parse(localStorage.getItem("res"));
  return {
   template: res.templates.headerTemplate.content,
   res: res,
  };
 },
 components: {
  VRuntimeTemplate,
 },
 watch: {
  // Watch for route changes
  $route() {
   this.res = JSON.parse(localStorage.getItem("res"));
  },
 },
 methods: {
  swalFire(obj) {
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result)=>{
    if (obj.isConfirmed&&result.isConfirmed) obj.isConfirmed(result);
   });
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return ;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  submitFormJson(formID, actionUrl, succ_call, error_call) {
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios
    .post(actionUrl, "json=" + JSON.stringify(formData), {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     console.log(response);
     if(succ_call) succ_call();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if(error_call) error_call();
    });
  },
 },
 mounted: function () {
  console.log("Header  mounted");

  /**
   * Sticky Header on Scroll
   */
  if (document.querySelector(".sticky")) {
   let headerOffset = document.querySelector(".sticky").offsetTop;

   const headerFixed = () => {
    if (headerOffset - window.scrollY <= 0) {
     document.querySelector(".sticky").classList.add("sticked");
     if (document.querySelector(".sticky").nextElementSibling)
      document
       .querySelector(".sticky")
       .nextElementSibling.classList.add("sticked-header-offset");
    } else {
     document.querySelector(".sticky").classList.remove("sticked");
     if (document.querySelector(".sticky").nextElementSibling)
      document
       .querySelector(".sticky")
       .nextElementSibling.classList.remove("sticked-header-offset");
    }
   };
   window.addEventListener("load", headerFixed);
   document.addEventListener("scroll", headerFixed);
  }
 },
};
</script>