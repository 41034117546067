<template>
 <div :class="'route_' + $route.name" ref="htmlContainer">
  <v-runtime-template :template="template"></v-runtime-template>
 </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import VRuntimeTemplate from "vue3-runtime-template";
import OwlCarousel from "./OwlCarousel.vue";
import DataList from "./DataList.vue";
import DataForm from "./DataForm.vue";
import FCalendar from "./FCalendar.vue";
import $ from "jquery";
import axios from "axios";
import Swal from "sweetalert2";
const api = axios.create({
 baseURL: "/api/",
});
export default {
 name: "CmsPage",
 components: {
  VRuntimeTemplate,
  FCalendar,
  OwlCarousel,
  DataList,
  DataForm,
 },
 data() {
  return {
   template: "",
   apiEndpoint: "Cms/PageData?pagename=" + this.$route.name,
   res: {},
   loading: false,
  };
 },
 watch: {
  // Watch for route changes
  $route() {
   this.reload();
  },
 },
 methods: {
  //新傳記用 sweetalert 開 form
  swalFire(obj) {
   if (obj.preConfirmFormID) {
    obj.preConfirm = this.preCheckForm(obj.preConfirmFormID);
   }
   Swal.fire(obj).then((result) => {
    if (obj.isConfirmed && result.isConfirmed) obj.isConfirmed(result);
   });
  },
  preCheckForm(formID) {
   return new Promise((resolve, reject) => {
    setTimeout(() => {
     const inputs = document.querySelectorAll(
      "#" + formID + " input,#" + formID + " textarea,#" + formID + " select"
     );
     for (let input of inputs) {
      const pattern = input.getAttribute("pattern");
      if (pattern && !new RegExp(pattern).test(input.value)) {
       reject("驗證失败");
       return;
      }
     }
     let result = {};
     inputs.forEach((input) => {
      result[input.name] = input.value;
     });
     resolve(result);
    }, 200);
   });
  },
  //新傳記用 submitFormJson jioushin 改 submitForm 並加上 valid
  //新傳紀用 "json=" + JSON.stringify(formData)  回歸formData
  submitForm(formID, actionUrl, succ_call, error_call) {
   console.log("postForm");
   if (this.loading) return;
   $("#" + formID + " p.error").remove();
   $("#" + formID + " .is-invalid").removeClass("is-invalid");
   var ei = $("input[required], select[required], textarea[required]").filter(
    function () {
     return $(this).val().trim() === "";
    }
   );
   if (ei.length > 0) {
    ei.addClass("is-invalid");
    $.each(ei, function (key, value) {
     if ($(value).attr("required-message"))
      $(value).after(
       "<p class='error invalid-feedback'>" +
        $(value).attr("required-message") +
        "</p>"
      );
    });
    $(ei[0]).focus();
    return;
   }
   ei = $("input[pattern], select[pattern], textarea[pattern]").filter(
    function () {
     var pattern = $(this).attr("pattern");
     var value = $(this).val();
     var regex = new RegExp(pattern);
     console.log(pattern)
     console.log(value)
     console.log(regex.test(value))
     return !regex.test(value);
    }
   );
   if (ei.length > 0) {
    ei.addClass("is-invalid").focus();
    $.each(ei, function (key, value) {
     if ($(value).attr("invalid-message"))
      $(value).after(
       "<p class='error invalid-feedback'>" +
        $(value).attr("invalid-message") +
        "</p>"
      );
    });
    return;
   }
   if (!confirm("確定送出?")) return;
   var formData = {};
   $("#" + formID)
    .find(":input[name]")
    .each(function () {
     formData[this.name] = $(this).val();
    });
   axios
    .post(actionUrl, formData, {
     headers: {
      "Content-Type": "application/x-www-form-urlencoded",
     },
    })
    .then((response) => {
     console.log(response);
     if (succ_call) succ_call();
     else this.reload();
    })
    .catch((error) => {
     console.error("Error fetching items:", error);
     if (error_call) error_call();
     else this.reload();
    });
  },
  replaceApiEndpoint(endpoint, params) {
   return endpoint
    .replace(/(\$\{route.name\})/g, this.$route.name)
    .replace(/(\$\{.+?\})/g, (match) => {
     if (match === "/api/") {
      // 替换掉 '/api/'
      return "";
     } else {
      // 提取变量名，去掉 ${} 包裹
      const paramName = match.slice(2, -1);
      // 返回对应的参数值，如果没有参数则保持原样
      return params[paramName] || match;
     }
    });
  },
  isUrlEncoded(url) {
   let oldUrl = "";
   let newUrl = decodeURIComponent(url);

   while (oldUrl !== newUrl) {
    if (!/%[0-9a-f]{2}/i.test(newUrl)) {
     return false; // 沒有編碼字符模式，不是雙重編碼
    }
    oldUrl = newUrl;
    try {
     newUrl = decodeURIComponent(oldUrl);
    } catch (e) {
     // 解碼錯誤，可能由於已經達到最底層的未編碼字符串
     return true;
    }
   }

   return oldUrl !== url; // 如果初始和最終字符串不相同，則為雙重編碼
  },
  customEncodeURIComponent(path) {
   // 將路徑分割為段，然後對每段進行編碼，最後重新組合
   if (!path) return null;
   return path
    .split("/")
    .map((segment) =>
     this.isUrlEncoded(segment) ? segment : encodeURIComponent(segment)
    )
    .join("/");
  },
  setupLinkListeners() {
   const container = this.$refs.htmlContainer;
   container.addEventListener("click", (e) => {
    console.log(this.customEncodeURIComponent(e.target.getAttribute("href")));
    if (
     e.target.tagName === "A" &&
     e.target.getAttribute("href") &&
     e.target.getAttribute("href")[0] == "/"
    ) {
     e.preventDefault();
     this.$router.push(
      this.customEncodeURIComponent(e.target.getAttribute("href"))
     );
    } else if (
     e.target.tagName === "BUTTON" &&
     e.target.getAttribute("@click")
    ) {
     if (
      e.target.getAttribute("@click").startsWith("submitFormJson(") &&
      e.target.getAttribute("@click").endsWith(")")
     ) {
      eval("this." + e.target.getAttribute("@click"));
     }
     if (
      e.target.getAttribute("@click").startsWith("swalFire(") &&
      e.target.getAttribute("@click").endsWith(")")
     ) {
      eval("this." + e.target.getAttribute("@click"));
     }
     e.preventDefault();
    }
   });
  },
  getCmsList() {
   // 檢查 apiEndpoint 是以 '/' 還是以 '{' 開頭
   if (this.apiEndpoint.startsWith("/")) {
    // 原始方式處理
    return api
     .get(this.replaceApiEndpoint(this.apiEndpoint, this.$route.params))
     .then((response) => response.data)
     .catch((error) => {
      console.error("Error fetching web config:", error);
      throw error;
     });
   } else if (this.apiEndpoint.startsWith("{")) {
    // 將 apiEndpoint 解析為 JSON 對象
    try {
     const endpoints = JSON.parse(this.apiEndpoint);
     const promises = Object.entries(endpoints).map(([key, endpoint]) => {
      return api
       .get(this.replaceApiEndpoint(endpoint, this.$route.params))
       .then((response) => ({ [key]: response.data }));
     });

     // 等待所有請求完成並合併結果
     return Promise.all(promises)
      .then((results) => Object.assign({}, ...results))
      .catch((error) => {
       console.error("Error fetching multiple web configs:", error);
       throw error;
      });
    } catch (error) {
     console.error("Error parsing JSON apiEndpoint:", error);
     throw error;
    }
   }
  },
  reload() {
   console.log("Page.vue mounted");
   this.apiEndpoint = "";
   this.template = "";
   var res = JSON.parse(localStorage.getItem("res"));
   var index = res.route.findIndex((x) => x.name == this.$route.name);
   if (
    index > -1 &&
    res.route[index].template &&
    res.route[index].template in res.templates
   ) {
    this.template = res.templates[res.route[index].template].content;
    if (
     res.templates[res.route[index].template].url &&
     res.templates[res.route[index].template].url != ""
    )
     this.apiEndpoint = res.templates[res.route[index].template].url;
   } else if (this.$route.name in res.templates) {
    this.template = res.templates[this.$route.name].content;
    if (
     res.templates[this.$route.name].url &&
     res.templates[this.$route.name].url != ""
    )
     this.apiEndpoint = res.templates[this.$route.name].url;
   }
   if (this.apiEndpoint && this.apiEndpoint != "") {
    $("#app").addClass("loading");
    this.getCmsList().then((res) => {
     this.res = res;
     setTimeout(() => {
      $("#app").removeClass("loading");
      $("html,body").animate({ scrollTop: 0 });
     }, 50);
    });
   }
   this.$nextTick(() => {
    this.setupLinkListeners();
   });
  },
 },
 mounted: async function () {
  console.log(this);
  this.reload();
 },
};
</script>