import { createApp } from "vue";
import App from "./App.vue";
import axios from "axios";
import { createRouter, createWebHashHistory } from "vue-router";
import CmsPage from "./views/CmsPage.vue";

export const api = axios.create({
  baseURL: "/api/",
});
window.$ = window.jQuery = require('jquery');
const getWebConfig = () => {
  return api
    .get("cms/web")
    .then((response) => response.data)
    .catch((error) => {
      // Handle errors here, e.g., log or throw an error
      console.error("Error fetching web config:", error);
      throw error;
    });
};

async function addRoute(a) {
  if (Array.isArray(a)) {
    a.forEach((b) => {
      addRoute(b);
    });
  } else {
    a.component = CmsPage;
  }
}
getWebConfig().then((res) => {

 localStorage.setItem("res", JSON.stringify(res));
 addRoute(res.route);
 console.log(res.route);
  let router = createRouter({
    history: createWebHashHistory(),
    routes: res.route,
  });

  const app = createApp(App);
  app.use(router);
  
  router.isReady().then(() => {
    // router.replace('/');  // 在路由準備好之後立即導航
    console.log(window.location.pathname )
    if(window.location.pathname != '/') {
      var path = window.location.pathname;
      history.replaceState(null, null, '/');
      router.push(path);
    }
    else if(window.location.hash && window.location.hash.startsWith("#/"))  router.push(decodeURIComponent(window.location.hash.substring(1)) );
  });
  
  app.mount("#app");
});
