<template>
 <div id="preview" v-if="preview == 'Y'">
  <span>預覽模式</span>
  <a id="closepreview" @click="closePreview()">
   <i class="fa fa-window-close"></i>
  </a>
 </div>
 <Header ref="Header" />
 <router-view name="leftview"></router-view>
 <router-view />
 <router-view name="rightview"></router-view>
 <Footer ref="Footer" />
 <a id="gotop">
  <i class="fa fa-angle-up"></i>
 </a>
 <div id="loading_modal"><i class="fas fa-2x fa-sync fa-spin"></i></div>
</template>

<script>
import Header from "./components/HeaderTemplate.vue";
import Footer from "./components/FooterTemplate.vue";
export default {
 name: "App",
 props: ["res"],
 components: {
  Header,
  Footer,
 },
 created: function () {
  // Create a new <style> element
  const styleTag = document.createElement("style");
  styleTag.type = "text/css";

  var res = JSON.parse(localStorage.getItem("res"));
  // Add your CSS styles here
  const cssStyles = res.css;

  // Set the content of the <style> element to your CSS styles
  styleTag.textContent = cssStyles;

  // Append the <style> element to the document's head
  document.head.appendChild(styleTag);
 },
 mounted: function () {
  console.log("App.vue mounted");
 },
};
</script> 